




















































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import CommonTitle from './component/CommonTitle.vue'

@Component({
    name: 'OurService',
    components: {
        CommonTitle,
    }
})
export default class extends Vue {
    hoverIndex: number = 0
    get deploymentList() {
        return [
            {
                label: this.$t('home.medicalCheck'),
                img: require('@/assets/images/deployment1@3x.png')
            },
            {
                label: this.$t('home.documentation'),
                img: require('@/assets/images/documentation@3x.png')
            },
            {
                label: this.$t('home.deployToOversea'),
                img: require('@/assets/images/deployment6@3x.png')
            }
        ]
    }
}
